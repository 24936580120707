/* Define keyframes for a subtle shake animation */
@keyframes rotate {
    0%, 100% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(-5deg); /* Rotate left */
    }
    40% {
        transform: rotate(5deg); /* Rotate right */
    }
    60% {
        transform: rotate(-5deg); /* Rotate left again */
    }
    80% {
        transform: rotate(5deg); /* Rotate right again */
    }
}
/* Add this to the .prompt-card class */
.prompt-card {
    animation: rotate 3s ease-in-out; /* Shorter 1 second animation */
    position: relative;
}

.prompt-card-NOTUSED::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 2px; /* To create the appearance of a "border-only" effect */
    background: none; /* We don't want to affect the inner content */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0); /* Initially, invisible */
    background: linear-gradient(90deg, transparent, #00CC7E, transparent); /* Green light */
    background-size: 200% 200%; /* Make the gradient bigger */
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    animation: veinFlow 2.5s linear infinite;
}

/* Keyframes for making the "vein" flow around the border */
@keyframes veinFlow {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 0%; /* Vein moves to halfway (or across) */
    }
    100% {
        background-position: 0% 0%; /* Vein returns to the start */
    }
}


