.survey-creator-container .sd-root-modern {
    --background: var(--color-white500);
    --sjs-secondary-backcolor-semi-light: var(--color-grey100);
    --primary: #e7e7e7;
    --primary-light: #e7e7e7;
    --primary-dark: #e7e7e7;

    --secondary: #273444;
    --secondary-light: #3C4858;
    --secondary-dark: #0D222F;

    --text-color: #404040;
    --text-light: #909090;
    --text-dark: #000000;
    --border-color: #e7e7e7;
    --error-color: #d52901;
    --success-color: #3c9d2b;
    --warning-color: #ff9800;
    --info-color: #2196f3;
}

/*Fonts*/
.survey-creator-container {
    font-family: var(--font-mont), var(--font-karla), system-ui;
    font-size: 2rem;
}

.sv-root-modern {
    font-family: var(--font-mont), var(--font-karla), system-ui !important;
}


/*Toolbox styles*/
.survey-creator-container .svc-toolbox__category-separator {
    display: none;
}


.survey-creator-container .sv-popup__body-content {
    z-index: 6 !important;
}

.survey-creator-container .svc-toolbox__scroller {
    padding: 0 !important;
    margin-right: 24px;
}

.survey-creator-container .svc-toolbox__category {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    justify-content: start;
}

.survey-creator-container .svc-toolbox__container {
    height: 100%;
}

.survey-creator-container .svc-page__question-type-selector {
    background-color: var(--color-blue700);
}

.survey-creator-container .svc-page__question-type-selector:hover,
.survey-creator-container .svc-page__question-type-selector:focus {
    background-color: var(--color-blue500);
}

.survey-tabs-container .svc-side-bar__shadow {
    display: none !important;
}

.survey-creator-container .svc-toolbox__container .svc-toolbox__item-title {
    color: var(--color-prussian500);
}

.survey-creator-container .svc-toolbox__item {
    z-index: 7 !important;
}


.survey-creator-container .svc-page__add-new-question {
    width: 100%;
    padding: 20px 40px; /* px-5 py-2.5, where 1 unit = 8px */
    transition: all 0.3s ease; /* approximation for 'transition' */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px; /* gap-1 */
    border-width: 2px;
    border-style: solid;
    border-radius: 32px; /* rounded-xxl, assuming this is your largest border radius */
    background-color: var(--color-blue700);
    border-color: var(--color-blue700);
    height: 4rem;
    flex-direction: row;
}

.survey-creator-container .svc-page__add-new-question:hover {
    background-color: var(--color-blue500);
    border-color: var(--color-blue500);
}

/* Optionally, to ensure text contrast */
.survey-creator-container .svc-page__add-new-question,
.survey-creator-container .svc-page__add-new-question.svc-btn .svc-text {
    color: var(--color-white700) !important;
}

.survey-creator-container .svc-page__add-new-question.svc-btn {
    height: 50px;
}

.survey-creator-container .svc-text {
    color: #404040;
}


/*Question Card styles */
.survey-creator-container .svc-question__content {
    border-radius: 8px; /* rounded-sm, but this might vary depending on your config */
    border: 1px solid var(--color-grey300);
    background-color: var(--color-white500);
    box-shadow: none !important;
    color: var(--color-white700) !important;
}

.survey-creator-container .svc-question__content:hover {
    border-color: var(--color-blue700);
}

.survey-creator-container .svc-question__content--selected {
    border-color: var(--color-blue700);
}

/*Settings styles*/
.survey-creator-container .svc-flex-column.svc-side-bar__wrapper {
    border: none !important;
    box-shadow: none !important;
}


/*Hovering*/

.survey-creator-container .svc-hovered-ready.svc-hovered:not(.svc-page__content--new) {
    background-color: var(--color-grey100);
}


.survey-tabs-container .svc-creator__content-wrapper {
    flex: 1;
    overflow: visible;
    height: 100%;
    min-height: 100%;
}

.survey-tabs-container .svc-creator__content-holder {
    height: 100%;
    min-height: 100%;
    overflow: visible;
}

/* This targets the actual content area */
.survey-tabs-container .svc-creator__content-holder > div {
    /*flex: 1;*/
    /*min-height: fit-content;*/
}


.survey-tabs-container .sv-action--hidden[id="settings"] {
    visibility: visible !important;
    width: unset;
    height: unset;
    overflow: unset;

}

body #creatorElement {
    --primary-hover-color: #6fe06f;
    --selection-border-color: #1ab394;
    --primary-icon-color: #3d4d5d;
}

body {
    --foreground-light: #909090;
    --primary: var(--color-blue700);
}

/*Settings tabs styles, hide and show*/

.survey-tabs-container .spg-row.spg-clearfix.sd-page__row:has(*[data-key="layout0"]),
.survey-tabs-container .spg-row.spg-clearfix.sd-page__row:has(*[data-key="logic0"]),
.survey-tabs-container .spg-row.spg-clearfix.sd-page__row:has(*[data-key="validation0"]),
.survey-tabs-container .spg-row.spg-clearfix.sd-page__row:has(*[data-key="data0"]),
.survey-tabs-container .spg-row.spg-clearfix.sd-page__row:has(*[data-key="rateValues0"]),
.survey-tabs-container .spg-row.spg-clearfix.sd-page__row:has(*[data-key="choices0"]),
.survey-tabs-container .spg-row.spg-clearfix.sd-page__row:has(*[data-key="choicesByUrl0"]),
.survey-tabs-container .spg-row.spg-clearfix > *[data-key="autocomplete0"],
.survey-tabs-container .sv-popup:has(.svc-toolbox__item-subtype){
    /*display: none;*/
}
