.ql-editor {
  min-height: 150px !important;
}

.rich-text-content {
  // Reset list styles to align with regular text
  ul, ol {
    padding-left: 0;
    margin-left: 1em; // Adjust this value to match your desired alignment

    // If you need to adjust nested lists
    ul, ol {
      margin-left: 1em;
    }
  }

  // If you're using bullet points, you might want to style them consistently
  ul {
    list-style-type: disc;
  }
}