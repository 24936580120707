.icons-container {
  position: relative;
  display: flex;
  align-items: center;
}

.icon-partial-overlay {
  position: relative;
  overflow: hidden;
  display: inline-block; /* Adjust as needed */
}

.icon-partial-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5); /* Example overlay, adjust color/opacity as needed */
}

.icon-wrapper{
  margin-left: 0;
    margin-right: 0;
}