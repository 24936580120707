.survey-sidebar-container {
    --background: var(--color-white500);
    position: relative;
}

.survey-sidebar-container .sv-button-group__item.sv-button-group__item--selected {
    background-color: var(--color-prussian500);
    color: var(--color-white700);
}


.survey-sidebar-container .sv-button-group__item.sv-button-group__item--selected .sv-string-viewer {
    color: var(--color-white700);
}

.survey-sidebar-container .spg-search-editor_container {
    display: none;
}

/* Title of each sub-section */
.survey-sidebar-container .spg-question__header.spg-question__header--location--top .sv-string-viewer {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
}

/*Setting backgrounds to var(--color-white500)*/
.survey-tabs-container .svc-side-bar__container-content .spg-panel__content,
.survey-tabs-container .svc-side-bar__container-content .spg-panel__title,
.survey-tabs-container .svc-side-bar__container-header {
    background-color: var(--color-white500);
}

/**Column view */
.survey-sidebar-container .spg-panel__content {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.survey-sidebar-container .spg-page.spg-body__page.sd-page__empty-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/*Hides selection of survey/questions*/
.survey-sidebar-container #svd-grid-object-selector {
    display: none;
}

/*Borders*/
/*box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1), 0 8px 12px 0 rgba(0, 0, 0, 0.15), 0 4px 6px -1px rgba(0, 0, 0, 0.2);*/

.survey-sidebar-container .svc-side-bar .svc-side-bar__container {
    box-shadow: 0px 4px 25px 3px rgba(211, 220, 230, 0.40), 0px 10px 14px 1px rgba(211, 220, 230, 0.50), 0px 6px 6px -3px rgba(211, 220, 230, 0.60);
}

.survey-tabs-container .svc-side-bar__container-header {
    box-shadow: none !important;
}

.survey-sidebar-container .svc-side-bar .svc-side-bar__container {
    position: absolute;
    /*top: 0;*/
    right: -126px !important;
    width: 475px;
    height: 600px !important;
    z-index: 13 !important;
    /*So that the toolbar does not overlap the sidebar*/
    top: calc(var(--top-bar-height) * 1);

}


.survey-sidebar-container .svc-side-bar .svc-side-bar__wrapper:has(> .svc-side-bar__container:not([style*="display: none"])) {
    position: sticky;
    top: 0;
    height: 50vh;
    z-index: 13;
}

