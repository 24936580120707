.exercise-card,
.exercise-card-advanced {
  cursor: pointer;
}

.exercise-card-advanced {
  background: #F9F9F9;
  transition: background 0.3s;
  padding: 16px 18px;
}

.exercise-card-advanced.locked {
  background: #DFE5ED;
}


.exercise-card-advanced.done {
  border-color: var(--color-green700);

}

.exercise-card-advanced:hover {
  background: #DFE5ED;
}