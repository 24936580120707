.no-exercise-title {
  //styleName: H6;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;

}

.no-exercise-subtitle {
  //styleName: p;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #3C4858;
  line-break: auto;
  margin-bottom: 24px;

}