/*Main Content*/
.survey-result-container .sa-visualizer__content.sa-panel__content.sa-grid.muuri {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/
    /*justify-content: space-between;*/
    /*width: 100%;*/
}

.survey-result-container .sa-question.sa-question-layouted.muuri-item.muuri-item-shown .sa-question__content {
    border: 1px solid var(--color-grey300);
    background-color: var(--color-white500);
    /*position: relative;*/
    /*width: 100%;*/
    /*margin-bottom: 40px;*/
    /*gap: 16px;*/
    /*display: flex;*/
    /*padding: 24px;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    border-radius: 4px;
    /*height: 100%;*/
    /*scale: 0.75!important;*/
    height: auto !important;
    /*min-width: fit-content !important;*/
}

.survey-result-container .sa-question.sa-question-layouted.muuri-item.muuri-item-shown {
    /*min-width: calc(var(--one-col) * 5 + var(--global-gap) * 4);*/
    /*width: fit-content;*/
    /*padding: 0 !important;*/

}

/*Title*/
.survey-result-container .sa-question__title.sa-question__title--draggable,
.survey-result-container .sa-visualizer__footer-title {
    width: 100% !important;
    font-size: 24px !important;
    line-height: 130%;
    color: var(--color-prussian500);
}

/*Question*/
/**
control: (styles, {isDisabled, isFocused, selectProps}) => {
    const innerPadding = selectProps?.customProps?.innerPadding;
    const minHeight = selectProps?.customProps?.minHeight;
    const gap = selectProps?.customProps?.gap ?? 0;
    const hideBorder = selectProps?.customProps?.hideBorder;
    let borderColor = 'var(--color-grey300)';
    if (isFocused) borderColor = 'var(--color-blue700)';
    if (isDisabled) borderColor = 'var(--color-grey500)';
    if (hideBorder) borderColor = 'transparent';

    return {
      ...styles,
      fontSize: '1rem',
      borderRadius: 8,
      padding: innerPadding,
      minHeight,
      gap,
      borderColor,
      // boxShadow: '0 0 0 1px var(--color-blue700)', // todo @jonny all borders should actually be boxShadow. Looks nicer
      boxShadow: 'none',
      backgroundColor: isDisabled ? 'var(--color-grey50)' : 'var(--color-white500)',
      '&:hover': {
        backgroundColor: 'var(--color-grey100)',
      },
    };
  },
 */
/*Select bar copied from above */
.survey-result-container select.sa-question__select {
    border-radius: 8px;
    padding: 8px;
    border: 1px solid var(--color-grey300);
    background-color: var(--color-white500);
    width: calc(var(--one-col) * 2 + var(--global-gap) * 2);
    height: auto !important;
}

.survey-result-container select.sa-question__select > option {
    /**
     marginTop: 4,
     boxShadow: 'none',
     backgroundColor: 'var(--color-white500)',
     border: '1px solid var(--color-grey300)',
     borderTopLeftRadius: 0,
     borderTopRightRadius: 0,
     borderBottomLeftRadius: 8,
     borderBottomRightRadius: 8,
     */
    background-color: var(--color-white500);
}

.survey-result-container select.sa-question__select:hover {
    background-color: var(--color-grey100);
}

.survey-result-container select.sa-question__select:focus {
    border: 1px solid var(--color-blue700);
}

.survey-result-container .sa-visualizer__footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.survey-result-container .modebar-container {
    display: none !important;
}