.survey-answer-container .sd-root-modern {
    font-family: var(--font-mont), var(--font-karla), system-ui;
    --primary: var(--color-blue700);
    --primary-foreground: var(--color-white700);
    background-color: var(--color-white500);
}

.survey-answer-container .sd-root-modern__wrapper {
    background-color: var(--color-white500) !important;
}

.survey-answer-container .sd-body.sd-body--static {
    background-color: var(--color-white500) !important;
    max-width: 100%;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.survey-answer-container .sv-components-row .sd-btn.sd-btn--action.sd-navigation__complete-btn {
    background-color: var(--color-blue700);
    border-color: var(--color-blue700);
    padding: 0 !important;
}

.survey-answer-container .sv-components-row .sd-btn.sd-btn--action.sd-navigation__complete-btn:hover {
    background-color: var(--color-blue500);
    border-color: var(--color-blue500);
    color: var(--color-white700) !important;
}

/*only for input tags*/
.survey-answer-container .sv-components-row .sv-action__content input[type="button"] {
    width: calc(var(--one-col) * 2 + var(--global-gap));
    padding: 20px 40px; /* px-5 py-2.5, where 1 unit = 8px */
    transition: all 0.3s ease; /* approximation for 'transition' */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px; /* gap-1 */
    border-width: 2px;
    border-style: solid;
    border-radius: 32px; /* rounded-xxl, assuming this is your largest border radius */
    background-color: var(--color-blue700);
    border-color: var(--color-blue700);
    height: 48px; /* h-6, where 1 unit = 8px */
    flex-direction: row;
}

.survey-answer-container .sv-components-row .sv-action__content input[type="button"]:hover {
    background-color: var(--color-blue500);
    border-color: var(--color-blue500);
    color: var(--color-white700) !important;
}

.survey-answer-container .sv-components-row .sd-element.sd-question {
    display: flex;
    padding: 16px; /* p-3, assuming 1 unit = 8px */
    flex-direction: column;
    align-items: flex-start;
    gap: 24px; /* gap-3, assuming 1 unit = 8px */
    border-radius: 8px; /* rounded-sm, but this might vary depending on your config */
    border: 1px solid var(--color-grey300);
    background-color: var(--color-white500);
    width: 100%;
    height: 100%;
    box-shadow: none !important;
    color: var(--color-white700) !important;
}

.survey-answer-container .sv-components-row .sd-btn.sd-btn--action.sd-navigation__complete-btn {
    color: var(--color-white700) !important;
}

.survey-answer-container .sv-components-row .sd-question__content {
    width: 100%;
}

.survey-answer-container .sd-page.sd-body__page,
.survey-answer-container .sd-title.sd-container-modern__title,
.survey-answer-container .sd-action-bar.sd-footer.sd-body__navigation.sd-clearfix {
    padding: 0 !important;
    background-color: var(--color-white500);
    box-shadow: none !important;
}


.survey-answer-container .sv-components-row .sd-action-bar.sd-footer.sd-body__navigation.sd-clearfix {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 24px;
}

.survey-answer-container .sv-components-row .sd-container-modern {
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.survey-answer-container .sv-components-row .sd-page.sd-body__page,
.survey-answer-container .sv-components-row .sd-body.sd-body--static {
    display: flex;
    flex-direction: column;
    gap: 16px;
}


.survey-answer-container .sd-title.sd-container-modern__title {
    margin-bottom: 16px;
}


.survey-answer-container .sv-components-row {
    width: 100%;
}

.survey-answer-container .sd-rating__item--selected .sv-string-viewer {
    color: var(--color-white700);
}

.survey-answer-container .sd-root-modern.sd-progress--pages.sd-root-modern--full-container {
    overflow-x: hidden;
}

/*hide alers*/

.survey-answer-container .sv-components-row .sd-row__question .sd-error.sd-element__erbox.sd-question__erbox {
    display: none;
}


.survey-answer-container .sv-components-column.sv-components-container-contentBottom {
    margin-top: 24px;
}
