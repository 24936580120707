.send-reminder {
  .pending-counter {
    border: 1px solid #ED6964;
    padding: 5px 15px;
    border-radius: 4px;
    font-weight: bold;
  }

  .finished-counter {
    border: 1px solid rgba(0, 204, 126, 0.85);
    padding: 5px 15px;
    border-radius: 4px;
    font-weight: bold;
  }
}
