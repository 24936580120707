/* Styles for the outer wrapper of the content */
.lockable-content-wrapper {
  position: relative; /* Needed to position the overlay correctly */
  width: 100%; /* Take full width of the parent */
  height: 100%; /* Take full height of the parent */
}

.lockable-content-overlay-exercises{
  position: absolute; /* Position over the children */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #DFE5EDCC;

  display: flex;
  justify-content: center; /* Center the message horizontally */
  align-items: start; /* Center the message vertically */
  z-index: 1; /* Ensure it sits above the children content */
}
/* Styles for the semi-opaque overlay */
.lockable-content-overlay {
  position: absolute; /* Position over the children */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #DFE5EDCC;

  display: flex;
  justify-content: center; /* Center the message horizontally */
  align-items: center; /* Center the message vertically */
  z-index: 1; /* Ensure it sits above the children content */
}

/* Styles for the lock message displayed over the content */
.lockable-content-message {
  color: white; /* White text color for visibility */
  font-size: 20px; /* Appropriately sized font for the message */
}


.lockable-content-message-exercises {
  color: white; /* White text color for visibility */
  font-size: 20px; /* Appropriately sized font for the message */
  position: sticky;
    top: 100px;
}
