
.survey-tabs-container .svc-tabbed-menu {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.survey-tabs-container .svc-tabbed-menu-item {
    transition: all 0.3s ease;
    display: flex;
    width: calc(var(--one-col) * 2 + var(--global-gap));
    height: 64px; /* h-8 */
    padding: 8px; /* p-1 */
    justify-content: center;
    align-items: center;
    gap: 8px; /* gap-1 */
    flex-shrink: 0;
    border-width: 1px;
    border-radius: 2px; /* Approximation for 'rounded-xs' */
    background-color: var(--color-white500);
    border-color: var(--color-grey300);
}

.survey-tabs-container .svc-tabbed-menu-item > .svc-text {
    font-size: 16px;
}

.survey-tabs-container .svc-tabbed-menu-item:hover {
    background-color: var(--color-grey100);
}

.survey-tabs-container .svc-tabbed-menu-item:disabled {
    background-color: var(--color-grey500);
    border-color: var(--color-grey300);
}

.survey-tabs-container .svc-tabbed-menu-item--selected {
    border-color: var(--color-blue700);
}

.survey-tabs-container .svc-tabbed-menu-item--selected {
    box-shadow: none;
}

.survey-tabs-container .svc-tab-designer {
    background-color: var(--color-white500);
    height: fit-content;
    min-height: 100%;
}


/*Main Body*/
.survey-tabs-container .svc-flex-column.svc-flex-row__element.svc-flex-row__element--growing {
    gap: 40px;
    box-shadow: none !important;
    height: fit-content;
}

.survey-tabs-container .svc-designer-header {
    border: none !important;
}

/*Main content full width*/
.survey-tabs-container .sd-container-modern.sd-container-modern--static {
    width: 100% !important;
    min-width: 100% !important;
}

.survey-tabs-container .svc-full-container {
    height: fit-content;
    background-color: var(--color-white500);
}

.survey-tabs-container .svc-full-container.svc-creator__area {
    border: none !important;
    /*min-height: 100%;*/
    height: fit-content;
}

/*Topbar styles*/
.survey-tabs-container .svc-top-bar {
    box-shadow: none !important;
}


.survey-tabs-container #svd-settings {
    display: none;
}


.survey-tabs-container,
.survey-tabs-container .svc-creator__content-wrapper,
.survey-tabs-container .svc-tab-designer,
.survey-tabs-container .sd-root-modern,
.survey-tabs-container .sd-container-modern,
.survey-tabs-container .svc-top-bar,
.survey-tabs-container .svc-toolbox__category,
.survey-tabs-container .svc-flex-column.svc-flex-row__element.svc-flex-row__element--growing,
.survey-tabs-container .sd-body {
    background-color: var(--color-white500);
}

.survey-tabs-container .svc-flex-column.svc-flex-row__element.svc-flex-row__element--growing,
.survey-tabs-container .svc-creator-tab,
.survey-tabs-container .svc-tab-designer {
    overflow: visible;
}

/*Sticky Toolbox*/

.survey-tabs-container .svc-flex-column:has(> .svc-toolbox.svc-toolbox--searchable.svc-toolbox--scrollable) {
    position: sticky;
    /*top: 0;*/
    height: fit-content;
    /*So that the toolbar does not overlap the sidebar*/
    top: calc(var(--top-bar-height) * 1);

}

.survey-tabs-container .sv-list__item:hover > .sv-list__item-body {
    background-color: var(--color-grey100);
}

.survey-tabs-container .svc-creator-tab > .svc-flex-column {
    z-index: 8 !important;
}

.survey-tabs-container .svc-tab-designer_content {
    min-height: 30vh;
    height: fit-content;
}
.survey-tabs-container .svc-tab-designer_content:has(.svc-tab-designer--with-place-holder) {
    min-height: unset;
    height: fit-content;
}
.survey-tabs-container .svc-creator-tab:has(.svc-tab-designer--with-place-holder),
.survey-tabs-container .svc-creator__content-holder:has(.svc-tab-designer--with-place-holder),
.survey-tabs-container .svc-creator__content-wrapper:has(.svc-tab-designer--with-place-holder){
    height: fit-content;
    min-height: unset;
    max-height: fit-content;
    flex-grow: unset;
    background-color: var(--color-white500);
}

.survey-tabs-container .svc-notifier.svc-notifier--info{
    display: none;
}