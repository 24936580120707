.exercise-status-badge {
  border-radius: 8px;
  display: flex;
  height: 24px;
  padding: 8px;
  align-items: center;
  justify-content: center;
}

.exercise-status-badge-circle {
  border-radius: 12px;
  display: flex;
  height: 24px;
  width: 24px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: var(--color-green700);
}