.institution-graph {
  height: 100%;
  width: 100%;

  .container {
    width: 500px;
    height: 500px; /* Set the height to a fixed value */
  }
}

.d3-tip {
  line-height: 1;
  font-size: 12px;
  padding: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
}